<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "BoCalendar",
  extends: Base,
  components: {
    DatePicker,
  },

  data() {
    return {
      Name: "BoCalendar",
      data: [],
      pageName: "",
      objectName: "",
      addItem: true,
      row: {},
      webSettings: {},
      heroImage: {},
      calendarHeroImage: "",
      date: null,
      dataLength: 0,
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData(() => {
      this.$set(
        this.$root.page,
        "ObjectName",
        this.replacePageTitle("Kalender Akademik", "Kalender Akademik")
      );
    });
    this.moduleRedirect();
  },
  methods: {
    sort() {
      this.filter.sort = this.filter.sort == "all" ? "" : "all";
      if (this.filter.sort == "all") {
        this.filter.status = "";
        this.filter.search = "";
      }
      var query = this.filter;
      delete query.page;
      this.$router.push({
        name: this.Name,
        query: query,
      });
    },
    editHeroImage(v) {
      $("#modalNewsImg").modal();
      this.calendarHeroImage = v;
    },
    submitHeroImage(e) {
      BOGen.apirest(
        "/" + this.Name,
        {
          type: "updateHero",
          heroImage: {
            as_val: {
              img: this.heroImage.as_val.img,
              img_mobile: this.heroImage.as_val.img_mobile,
              alt_img: this.heroImage.as_val.alt_img,
              alt_img_mobile: this.heroImage.as_val.alt_img_mobile
            },
          },
        },
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".kr-img-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".kr-img-info");
            setTimeout(() => {
              $("#modalNewsImg").modal("hide");
            }, 500);
            this.refreshData();
          }
        },
        "POST"
      );
    },
  },
  computed: {
    startDate: {
      get: function() {
        return this.row.aca_date_start
          ? new Date(this.row.aca_date_start)
          : null;
      },
      set: function(newValue) {
        this.$set(this.row, "aca_date_start", `${newValue}`.dates("datetime"));
      },
    },
    endDate: {
      get: function() {
        return this.row.aca_date_end ? new Date(this.row.aca_date_end) : null;
      },
      set: function(newValue) {
        this.$set(this.row, "aca_date_end", `${newValue}`.dates("datetime"));
      },
    },
    filterStatus: {
      get(){
        return this.filter.status;
      },
      set(newValue){
        this.filter = {
          ...this.filter,
          status: newValue
        }
        this.search();
      }
    },
  },
  watch: {
    "$route.params"() {
      this.$set(
        this.$root.page,
        "ObjectName",
        this.replacePageTitle("Kalender Akademik", "Kalender Akademik")
      );
    },
    "$route.query"() {
      if (this.$route.params.id) {
        this.$set(this.row, "status", this);
        this.$set(this.row, "search", this);
        this.$set(this.row, "page", this);
        setTimeout(() => {
          this.row.status = this.$route.query.status;
          this.row.search = this.$route.query.search;
          this.row.page = this.$route.query.page;
        }, 500);
      }
      this.refreshData();
    },
    "row.aca_name"(v) {
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='aca_name'] label").text("Input min 3 characters");
        } else {
          $("[error='aca_name']").html("");
        }
      }
    },
    "row.aca_desc"(v) {
      if (v.length > 0) {
        $("[error='aca_desc']").html("");
      }
    },
    "row.aca_location"(v) {
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='aca_location'] label").text("Input min 3 characters");
        } else {
          $("[error='aca_location']").html("");
        }
      }
    },
    "row.aca_date_start"(v) {
      if (v.length > 0) {
        $("[error='aca_date_start']").html("");
      }
    },
    "row.aca_date_end"(v) {
      if (v.length > 0) {
        $("[error='aca_date_end']").html("");
      }
    },
  },
};
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              Hero Image Kalender Akademik
            </h5>
          </div>
          <div class="card-body">
            <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <div class="row">
                <div v-if="moduleRole('Edit') && !$route.params.id" class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="hero"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image Mobile'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="heromobile"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img_mobile"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img"
                        v-model="heroImage.as_val.alt_img"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Yayasan Al Hidayah'
                        }"
                        :label="'Alt Image'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img_mobile"
                        v-model="heroImage.as_val.alt_img_mobile"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Yayasan Al Hidayah'
                        }"
                        :label="'Alt Image'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                  </div>
                </div>
                <div v-else class="col-md-8">
                  <img
                    :src="uploader(heroImage.as_val.img)"
                    :alt="heroImage.as_val.alt_img"
                  />
                </div>
                <div class="col-12 mt-3 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalNewsImg"
      v-if="moduleRole('Edit') && !$route.params.id"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ubah Hero Image</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="ti-close"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <BoField
                name="image"
                mandatory
                :label="'Hero Image'"
                class="mb-0"
              >
                <Uploader
                  name="heroImage"
                  :param="{ thumbnail: true }"
                  type="hero"
                  :deleted="false"
                  uploadType="cropping"
                  v-model="calendarHeroImage"
                ></Uploader>
              </BoField>

              <BoField
                mandatory
                name="ai_alt_img"
                v-model="heroImage.as_val.alt_img"
                :attr="{
                  type: 'text',
                  placeholder: 'e.g. Hero Image Kalender Akademik'
                }"
                :label="'Alt Image'"
                required=""
              ></BoField>

              <div class="col-12 mt-3 text-right">
                <button
                  v-if="moduleRole('Edit')"
                  type="submit"
                  class="btn btn-rounded btn-loading btn-info"
                >
                  Perbarui
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="!$route.params.id">
        <div class="card">
          <div class="card-body">
            <VForm @resp="search">
              <div class="row align-items-center gutter-10">
                <div class="col-lg-6 col-sm-12 col-6">
                  <h5 class="card-title mb-0">Daftar Kalender Akademik</h5>
                </div>
                <div
                  v-if="dataLength > 0"
                  class="ml-sm-auto ml-lg-0 col-lg-2 col-sm-2"
                >
                  <select2 :options="status" v-model="filterStatus">
                    <option value="">-- Pilih Status --</option>
                  </select2>
                </div>
                <div v-if="dataLength > 0" class="col-9 col-lg-3 col-sm-4">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input
                        type="text"
                        v-model="filter.search"
                        v-on:keyup.enter="search"
                        class="form-control"
                        placeholder="Cari..."
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-info"
                          type="button"
                          @click="search()"
                        >
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="dataLength > 0" class="col-3 col-lg-1 col-sm-auto">
                  <router-link
                    :to="{ name: Name }"
                    class="btn btn-block btn-warning"
                    >Reset</router-link
                  >
                </div>
              </div>
            </VForm>
          </div>

          <div class="table-responsive">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nama Kegiatan</th>
                  <th>Tanggal dan Waktu</th>
                  <th>Lokasi</th>
                  <th>Status</th>
                  <th
                    width="120px"
                    v-if="
                      moduleRole('Edit') ||
                        moduleRole('Delete')
                    "
                  >
                    Aksi
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, k) in data.data" :key="k">
                  <td>{{ k + 1 }}</td>
                  <td>{{ v.aca_name }}</td>
                  <td>{{ v.aca_date_start.dates('format') }} - {{ v.aca_date_end.dates('format') }}</td>
                  <td>{{ v.aca_location }}</td>
                  <td>
                    <label
                      v-if="v.aca_status == 'Y'"
                      class="label label-success"
                      >Active</label
                    >
                    <label
                      v-if="v.aca_status == 'N'"
                      class="label label-warning"
                      >Inactive</label
                    >
                  </td>
                  <td
                    v-if="
                      moduleRole('Edit') ||
                        moduleRole('Delete')
                    "
                  >
                    <router-link
                      v-if="moduleRole('Edit')"
                      :to="{
                        name: Name,
                        params: { id: v.aca_id },
                      }"
                      class="icon_action mr-1"
                      v-tooltip="'Ubah'"
                    >
                      <i class="ti-marker-alt"></i>
                    </router-link>
                    <a
                      v-if="moduleRole('Edit')"
                      href="javascript:;"
                      class="icon_action mr-1"
                      @click="
                        changeStatusDynamic(
                          v,
                          'aca_status',
                          'aca_id',
                          'AppCalendar'
                        )
                      "
                      v-tooltip="'Ubah Status'"
                      ><i class="icon-settings"></i
                    ></a>
                    <a
                      v-if="moduleRole('Delete')"
                      @click="deleteItemId(v.aca_id, k)"
                      href="javascript:;"
                      class="icon_action"
                      v-tooltip="'Hapus'"
                      ><i class="ti-trash"></i
                    ></a>
                  </td>
                </tr>
                <tr v-if="NotFound" class="text-center col-md-12">
                  <td colspan="6" class="tc">{{ NotFound }}</td>
                </tr>
                <tr v-if="data.data === false" class="text-center col-md-12">
                  <td colspan="6" class="tc">
                    <LoadingSpinner light></LoadingSpinner>
                  </td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </table>
          </div>
          <div class="card-body">
            <Pagination
              class="justify-content-end m-0"
              :data="data"
              :limit="3"
              @pagination-change-page="onPaging"
            ></Pagination>
          </div>
        </div>
      </div>
      <div class="col-12" v-if="$route.params.id">
        <div class="card">
          <VForm method="post" @resp="submitForm">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-8">
                  <h5 class="card-title">
                    {{ row.aca_id ? "Ubah" : "Tambah" }} {{ ObjectName }}
                  </h5>
                </div>
              </div>
              <div class="info"></div>
              <div class="row">
                <div class="col-md-6">
                  <BoField
                    class="mb-0"
                    mandatory
                    v-model="row.aca_name"
                    label="Nama Event"
                    :attr="{placeholder: 'e.g. Pengambilan raport'}"
                  ></BoField>
                  <div error="aca_name"></div>
                  <BoField mandatory class="mb-0 mt-2" label="Deskripsi" >
                    <textarea
                      v-model="row.aca_desc"
                      id=""
                      class="form-control"
                      rows="4"
                      placeholder="e.g. Pembagian hasil laporan rapot bagi kelas 1 hingga kelas 6"
                    aca_></textarea>
                  </BoField>
                  <div error="aca_desc"></div>
                  <BoField
                    name="aca_location"
                    v-model="row.aca_location"
                    label="Lokasi"
                    :attr="{placeholder: 'e.g. Lapangan SDI Al Hidayah'}"
                    class="mb-0"
                  ></BoField>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <BoField mandatory class="mb-0 col-lg-5" label="Tanggal Mulai">
                      <div>
                        <date-picker
                          placeholder="DD-MM-YYYY"
                          v-model="startDate"
                          type="date"
                          format="DD-MM-YYYY"
                          :disabled-date="(date) => date < new Date().setDate(new Date().getDate() - 1)"
                        ></date-picker>
                        <div error="aca_date_start"></div>
                      </div>
                    </BoField>
                    <div class="col-lg-2 mb-2 mtc">
                      <div class="span input-group-text p-1">
                        Sampai
                      </div>
                    </div>
                    <BoField mandatory label="Tanggal Selesai" class="mb-0 col-lg-5">
                      <div>
                        <date-picker
                          type="date"
                          format="DD-MM-YYYY"
                          v-model="endDate"
                          placeholder="DD-MM-YYYY"
                          :disabled-date="(date) => date < new Date(startDate)"
                        ></date-picker>
                        <div error="aca_date_end"></div>
                      </div>
                    </BoField>
                  </div>
                  <BoField mandatory class="mb-0" label="Status">
                    <radio
                      option="Y"
                      v-model="row.aca_status"
                      :attr="{ checked: true }"
                      >Active</radio
                    >
                    <radio option="N" v-model="row.aca_status">Inactive</radio>
                  </BoField>
                  <div error="aca_status"></div>
                </div>
                <div class="col-sm-12">
                  <div class="text-right">
                    <router-link
                      :to="{name: $route.name}"
                      class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                    >
                      Kembali
                    </router-link>
                    <button
                      type="submit"
                      class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded"
                    >
                      {{ row.id ? "Ubah" : "Tambah" }}
                      <i class="icon-arrow-right14 position-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .mtc {
    margin-top: 5.5%;
  }
  .mtc .span{
    display: flex;
    justify-content : center;
  }
  @media(max-width: 768px){
    .mtc {
      margin-top: 0%;
    }  
  }
</style>
